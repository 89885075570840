import './siteheader.scss';

document.addEventListener('DOMContentLoaded', function () {
    let lastHeaderColor = null;
    const sectionOffsetY = 15 + 1;

    const mobileNav = document.querySelector('[data-mobilenav]');
    const body = document.querySelector('body');
    const mobileNavOpen = document.querySelector('[data-mobilenav-open]');
    const mobileNavClose = document.querySelector('[data-mobilenav-close]');

    const firstLine = mobileNav.querySelector('.first-line');
    const secondLine = mobileNav.querySelector('.second-line');
    const handleMobileNavOpen = () => {
        mobileNav.classList.add('siteheader__mobilenav-visible', true);
        firstLine.classList.add('rotate');
        secondLine.classList.add('rotate');
        body.style.position = 'fixed';
    };
    const handleMobileNavClose = () => {
        body.style.position = 'relative';
        mobileNav.classList.remove('siteheader__mobilenav-visible', false);
        firstLine.classList.remove('rotate');
        secondLine.classList.remove('rotate');
    };
    const handleToggleSubmenu = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const target = e.currentTarget.closest('.siteheader__nav-item');
        if (!target) return;

        target.classList.toggle(
            'is--open',
            !target.classList.contains('is--open')
        );
    };

    mobileNavOpen.removeEventListener('click', handleMobileNavOpen);
    mobileNavOpen.addEventListener('click', handleMobileNavOpen);

    mobileNavClose.removeEventListener('click', handleMobileNavClose);
    mobileNavClose.addEventListener('click', handleMobileNavClose);

    mobileNav
        .querySelectorAll('.siteheader__nav-item--mobile-taptarget')
        .forEach((item) => {
            item.removeEventListener('click', handleToggleSubmenu);
            item.addEventListener('click', handleToggleSubmenu);
        });

    const header = document.querySelector('header.siteheader');
    const changeHeaderColor = (sectionData) => {
        // on some sections the header color will not change
        // e.g. sliders with images
        if (!!sectionData?.noheaderchange || !sectionData?.bg) return;

        let newColor = header?.dataset.bg;

        if (sectionData.bg === 'bg-white') {
            newColor = 'white';
        } else if (sectionData.bg === 'bg-ds-blue') {
            newColor = 'blue';
        } else if (sectionData.bg === 'bg-ds-blue-light') {
            newColor = 'light-blue';
        } else if (sectionData.bg === 'bg-ds-blue-dark') {
            newColor = 'dark-blue';
        } else if (sectionData.bg === 'bg-ds-sand-light') {
            newColor = 'sand';
        } else if (sectionData.bg === 'bg-ds-green') {
            newColor = 'green';
        } else if (sectionData.bg === 'bg-ds-lime') {
            newColor = 'lime';
        }

        header.dataset.bg = newColor;
    };

    const handleScroll = () => {
        const sections = [
            ...Array.from(document.querySelectorAll('section.modifies-header')),
            document.querySelector('footer.sitefooter')
        ].map((section) => ({
            top: section.offsetTop - header.offsetHeight,
            section
        }));

        const scroll = window.scrollY - sectionOffsetY;

        const currentSection =
            scroll < sections[0].top
                ? sections[0].section
                : sections.filter((s) => scroll >= s.top).reverse()?.[0];

        const isFooter = currentSection.section.nodeName === 'FOOTER';

        if (
            typeof lastHeaderColor === 'undefined' ||
            !lastHeaderColor ||
            (lastHeaderColor &&
                lastHeaderColor !== currentSection.section?.dataset.bg) ||
            isFooter
        ) {
            changeHeaderColor(
                isFooter
                    ? {
                          noheaderchange: false,
                          bg: 'bg-ds-blue-dark'
                      }
                    : currentSection.section?.dataset
            );

            if (currentSection.section?.dataset?.noheaderchange) {
                lastHeaderColor = null;
            } else {
                lastHeaderColor = isFooter
                    ? 'bg-ds-blue-dark'
                    : currentSection.section?.dataset?.bg;
            }
        }
    };
    document.removeEventListener('scroll', handleScroll);
    document.addEventListener('scroll', handleScroll);
});
